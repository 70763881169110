import ConvivaSDK from '@convivainc/conviva-js-coresdk';
import { ConvivaService } from './conviva';
import { toDeviceCategory, toPlayerName } from './utils/platformSpecific';

class FlutterWebConvivaService extends ConvivaService {
    constructor(platform: string) {
        const deviceData = {
            category: toDeviceCategory(platform),
        };
        const playerName = toPlayerName(platform);

        super(ConvivaSDK, deviceData, playerName);

        window.__flutter_web_conviva_service = this;
    }
}

declare global {
    interface Window {
        __flutter_web_conviva_service: FlutterWebConvivaService,
        WebConvivaServiceNamespace: {
            FlutterWebConvivaService: { new(platform: string): FlutterWebConvivaService },
        }
    }
}

window.WebConvivaServiceNamespace = {
    FlutterWebConvivaService
};